import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { white } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Wrapper from 'components/Wrapper'
import Section from 'components/Section'
import PhotoMosaic from 'components/PhotoMosaic'
import SectionHeading from 'components/SectionHeading'
import Button from 'components/button/Button'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'

const Container = styled.div`
  background-color: ${white};
  overflow-x: hidden;
`

const Content = styled.div`
  position: static;

  ${media.medium`
    padding-right: 7.5%;
  `};

  ${media.large`
    padding-right: 10%;
  `};

  ${media.xLarge`
    padding-right: 15%;
  `};
`

const FigureWrap = styled.div`
  position: static;
  margin-top: ${spacing.small};

  ${media.small`
    margin-top: ${spacing.medium};
  `};

  ${media.medium`
    margin-top: 0;
    margin-right: -15%;
  `};

  ${media.large`
    margin-right: -160px;
  `};

  ${media.xLarge`
    margin-left: 20px;
    margin-right: -220px;
  `};
`

const Experience = ({ cmsData }) => {
  const intl = useIntl()

  return (
    <Container id='concept'>
      <Section>
        <Wrapper>
          <GridRow middle>
            <GridItem xs='1-1' md='1-2' pushLg='1-12' lg='5-12'>
              <Content>
                <SectionHeading
                  eyebrow={cmsData.eyebrow[0].text}
                  heading={cmsData.heading[0].text}
                  subheading={cmsData.description[0].text}
                >
                  <Button to='/concept' theme='secondary'>
                    {intl.formatMessage({ id: 'learnMore' })}
                  </Button>
                </SectionHeading>
              </Content>
            </GridItem>
            <GridItem xs='1-1' md='1-2' lg='5-12'>
              <FigureWrap>
                <PhotoMosaic />
              </FigureWrap>
            </GridItem>
          </GridRow>
        </Wrapper>
      </Section>
    </Container>
  )
}

export default Experience
