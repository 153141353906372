import React from 'react'
import styled from 'styled-components'
import { string, number } from 'prop-types'
import { useIntl, Link } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { white, greyDark, black } from 'styles/colors'
import { boxShadow, cover } from 'styles/helpers'
import { animationTime, animationCurve } from 'styles/variables'
import * as spacing from 'styles/spacing'
import GatsbyImage from 'gatsby-image'

import Heading from 'components/Heading'
import SmallCaps from 'components/SmallCaps'

const Container = styled(Link)`
  display: block;
  text-align: left;
`

const FigureWrap = styled.div`
  position: relative;
  background-color: ${black};
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    padding-bottom: 120%;
    display: block;
  }
`

const Figure = styled(GatsbyImage)`
  ${cover('absolute')};
  object-fit: cover;
  position: absolute !important;

  transition: transform ${animationTime} ${animationCurve},
    opacity ${animationTime} ${animationCurve};

  ${Container}:hover & {
    transform: scale(1.05);
    opacity: 0.95;
  }
`

const Details = styled.div`
  position: relative;
  z-index: 10;
  padding: ${spacing.large};
  background-color: ${white};
  margin-left: ${spacing.medium};
  margin-right: ${spacing.medium};
  margin-top: -${spacing.xxxLarge};
  ${boxShadow};

  ${media.small`
    margin-left: ${spacing.large};
    margin-right: ${spacing.large};
  `};

  ${media.large`
    padding: ${spacing.xLarge};
  `};

  ${media.xLarge`
    margin-left: ${spacing.xLarge};
    margin-right: ${spacing.xLarge};
  `};
`

const Meta = styled(SmallCaps)`
  color: ${greyDark};
  display: block;
`

const RoomCard = ({ name, beds, size, slug, fluid }) => {
  const intl = useIntl()

  return (
    <Container to={`/apartments/${slug}`}>
      <FigureWrap>
        <Figure fluid={fluid} />
      </FigureWrap>
      <Details>
        <Heading size={3}>{name}</Heading>
        <Meta>
          {beds} {intl.formatMessage({ id: 'bed' })}
          {beds > 1 ? 's' : ''} • {size} {intl.formatMessage({ id: 'sqft' })}
        </Meta>
      </Details>
    </Container>
  )
}

RoomCard.propTypes = {
  name: string.isRequired,
  beds: number.isRequired,
  size: number.isRequired,
  slug: string.isRequired,
}

export default RoomCard
