import React from 'react'
import styled from 'styled-components'

import { media } from 'styles/media'
import { boxShadow, cover } from 'styles/helpers'
import { borderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: ${spacing.medium};

  ${media.medium`
    grid-gap: ${spacing.large};
  `};

  ${media.large`
    grid-gap: ${spacing.xLarge};
  `};
`

const Item = styled.div`
  position: relative;
  background-color: ${borderColor};
  ${boxShadow};

  &:after {
    content: '';
    display: block;
    width: 100%;
  }

  &:nth-child(1) {
    grid-area: 1 / 1 / 4 / 5;

    &:after {
      padding-bottom: 76%;
    }
  }
  &:nth-child(2) {
    grid-area: 2 / 5 / 6 / 8;

    &:after {
      padding-bottom: 117%;
    }
  }

  &:nth-child(3) {
    grid-area: 4 / 2 / 7 / 5;

    &:after {
      padding-bottom: 70%;
    }
  }
`

const Photo = styled.img`
  ${cover('absolute')};
  object-fit: cover;
`

const PhotoMosaic = () => (
  <Container>
    <Item>
      <Photo src={require(`assets/images/concept/bedroom.jpg`)} />
    </Item>
    <Item>
      <Photo src={require(`assets/images/concept/washer.jpg`)} />
    </Item>
    <Item>
      <Photo src={require(`assets/images/concept/kitchen.jpg`)} />
    </Item>
  </Container>
)

export default PhotoMosaic
