import React from 'react'

import Layout from 'components/layout'
import Hero from 'components/home/Hero'
import Suites from 'components/home/Suites'
import MetaTags from 'components/MetaTags'
import Neighbourhood from 'components/home/Neighbourhood'
import Experience from 'components/home/Experience'
import { graphql } from 'gatsby'
// import Instagram from 'components/Instagram'

const Home = ({ data }) => {
  const {
    headerImage: {
      childImageSharp: { fluid },
    },
    prismic: { allApartments },
  } = data

  return (
    <Layout>
      <MetaTags title='Hotel Griffintown' />
      <Hero fluid={fluid} cmsData={data.prismic.allHeros.edges[0].node} />
      <Suites
        cmsData={data.prismic.allSuites_pages.edges[0].node}
        apartments={allApartments.edges}
      />
      <Neighbourhood cmsData={data.prismic.allNeighbourhoods.edges[0].node} />
      <Experience cmsData={data.prismic.allAmenitiess.edges[0].node} />

      {/* <Instagram /> */}
    </Layout>
  )
}

export const query = graphql`
  query Home($language: String!) {
    headerImage: file(name: { eq: "img-hero-home" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismic {
      allNeighbourhoods(lang: $language) {
        edges {
          node {
            eyebrow
            heading
            description
          }
        }
      }
      allHeros(lang: $language) {
        edges {
          node {
            heading
            description
          }
        }
      }
      allSuites_pages(lang: $language) {
        edges {
          node {
            eyebrow
            heading
            subheading
          }
        }
      }
      allAmenitiess(lang: $language) {
        edges {
          node {
            eyebrow
            heading
            description
          }
        }
      }
      allApartments(sortBy: sort_ASC, lang: $language) {
        edges {
          node {
            size
            title
            featured_picture
            featured_pictureSharp(crop: "homeCard") {
              childImageSharp {
                fluid(maxWidth: 676, maxHeight: 812) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
            beds {
              quantity
            }
            short_description
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

export default Home
