import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'

import { media } from 'styles/media'
import { white, greyDark } from 'styles/colors'
import { cover, boxShadow } from 'styles/helpers'
import * as spacing from 'styles/spacing'

import Map from 'components/Map'
import Wrapper from 'components/Wrapper'
import Section from 'components/Section'
import SectionHeading from 'components/SectionHeading'
import BookButton from 'components/button/BookButton'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'

const Container = styled.div`
  position: relative;
  background-color: ${greyDark};
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  background-color: ${white};
  padding: ${spacing.large};
  z-index: 10;
  ${boxShadow};
  margin-top: 14rem;

  ${media.small`
    padding: ${spacing.xxLarge};
    margin-top: 18rem;
    max-width: 640px;
  `};

  ${media.medium`
    max-width: 450px;
    padding: ${spacing.xxLarge};
    margin-top: 0;
  `};

  ${media.large`
    max-width: 520px;
    padding: ${spacing.xxxLarge};
  `};

  ${media.xLarge`
    max-width: 590px;
    padding: 76px;
  `};
`

const MapWrap = styled.div`
  ${cover('absolute')};
`

const Neighbourhood = ({ cmsData }) => {
  const intl = useIntl()

  return (
    <Container id='neighbourhood'>
      <Section>
        <Wrapper>
          <Content>
            <SectionHeading
              eyebrow={cmsData.eyebrow[0].text}
              heading={cmsData.heading[0].text}
              subheading={cmsData.description[0].text}
            >
              <ButtonGroup>
                <BookButton />
                <Button to='/neighbourhood' theme='secondary'>
                  {intl.formatMessage({ id: 'learnMore' })}
                </Button>
              </ButtonGroup>
            </SectionHeading>
          </Content>
        </Wrapper>
      </Section>
      <MapWrap>
        <Map center={{ lat: 45.491659, lng: -73.564751 }} zoom={16} styled />
      </MapWrap>
    </Container>
  )
}

export default Neighbourhood
