import React from 'react'
import styled from 'styled-components'
import { math } from 'polished'
import { useIntl } from 'gatsby-plugin-intl'

import { white } from 'styles/colors'
import { media, mediaDown } from 'styles/media'
import * as spacing from 'styles/spacing'

import Wrapper from 'components/Wrapper'
import RoomCard from 'components/RoomCard'
import Section, { SectionBlock } from 'components/Section'
import SectionHeading from 'components/SectionHeading'
import Button from 'components/button/Button'

const Container = styled.div`
  background-color: ${white};
  overflow-x: hidden;
  text-align: center;
`

const HeadingWrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 540px;

  ${media.medium`
    max-width: 560px;
  `};

  ${media.large`
    max-width: 600px;
  `};

  ${media.xLarge`
    max-width: 660px;
  `};
`

const Inner = styled.div`
  /* overflow: hidden; */
  margin-left: -${spacing.large};
  margin-right: -${spacing.large};

  ${media.small`
    margin-left: -${spacing.xxLarge};
    margin-right: -${spacing.xxLarge};
  `};

  ${media.medium`
    margin-left: 0;
    margin-right: 0;
  `};
`

const Grid = styled.div`
  display: grid;

  ${mediaDown.medium`
    padding-bottom: ${spacing.large};
    margin-bottom: -${spacing.large}; /* Clip scroll bar */
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
  `};

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${spacing.xLarge};
  `};
`

const Item = styled.div`
  position: static;
  display: inline-block;
  margin-right: ${spacing.large};
  scroll-snap-align: center;
  width: 75%;

  &:first-child {
    margin-left: ${math('1/8') * '100' + 'vw'};
  }

  &:last-child {
    margin-right: ${math('1/8') * '100' + 'vw'};
  }

  ${media.small`
    width: 50%;

    &:first-child {
      margin-left: ${math('1/4') * '100' + 'vw'};
    }

    &:last-child {
      margin-right: ${math('1/4') * '100' + 'vw'};
    }
  `};

  ${media.medium`
    margin: 0;
    display: block;
    width: 100%;

    &:last-child,
    &:first-child {
      margin: 0;
    }
  `};
`

const Suites = ({ cmsData, apartments }) => {
  const intl = useIntl()

  return (
    <Container id='suites'>
      <Section>
        <Wrapper>
          <SectionBlock>
            <HeadingWrap>
              <SectionHeading
                eyebrow={cmsData.eyebrow[0].text}
                heading={cmsData.heading[0].text}
                subheading={cmsData.subheading[0].text}
              />
            </HeadingWrap>
          </SectionBlock>
          <SectionBlock>
            <Inner>
              <Grid>
                {apartments.map(({ node }) => {
                  const beds = node.beds.reduce(
                    (acc, cur) => (acc += cur.quantity),
                    0
                  )

                  const slug = node._meta.uid || ''

                  return (
                    <Item>
                      <RoomCard
                        name={node.title}
                        beds={beds}
                        size={node.size}
                        slug={slug}
                        fluid={
                          node?.featured_pictureSharp?.childImageSharp?.fluid
                        }
                      />
                    </Item>
                  )
                })}
              </Grid>
            </Inner>
          </SectionBlock>
          <SectionBlock>
            <Button to='/apartments' theme='secondary'>
              {intl.formatMessage({ id: 'home.suites.explore' })}
            </Button>
          </SectionBlock>
        </Wrapper>
      </Section>
    </Container>
  )
}

export default Suites
