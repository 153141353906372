import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useIntl } from 'gatsby-plugin-intl'

import { widowFix } from 'utils/string'
import { navHeightMobile, navHeight } from 'styles/variables'
import { white, black } from 'styles/colors'
import { media } from 'styles/media'
import { cover } from 'styles/helpers'
import { Lead } from 'styles/typography'

import Wrapper from 'components/Wrapper'
import Nav from 'components/Nav'
import Heading from 'components/Heading'
import BookButton from 'components/button/BookButton'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Img from 'gatsby-image'

const Container = styled.div`
  position: relative;
  background-color: #222;
  padding-top: ${navHeightMobile};

  &:after {
    content: '';
    ${cover('absolute')};
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  ${media.medium`
    padding-top: ${navHeight};
    background-attachment: fixed;
  `};
`

const Inner = styled.div`
  position: static;
  padding-top: 4rem;
  padding-bottom: 6rem;

  ${media.small`
    padding-top: 6rem;
    padding-bottom: 9rem;
  `};

  ${media.medium`
    padding-top: 8rem;
    padding-bottom: 13rem;
  `};

  ${media.large`
    padding-top: 10rem;
    padding-bottom: 15rem;
  `};

  ${media.xLarge`
    padding-top: 12rem;
    padding-bottom: 18rem;
  `};
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  max-width: 430px;

  ${media.small`
    max-width: 460px;
  `};

  ${media.medium`
    max-width: 520px;
  `};

  ${media.large`
    max-width: 530px;
  `};

  ${media.xLarge`
    max-width: 560px;
  `};
`

const Title = styled(Heading)`
  color: ${white};
  text-shadow: 0px 2px 4px ${rgba(black, 0.25)};
`

const Subtitle = styled(Lead)`
  color: ${rgba(white, 0.7)};
`

const Background = styled(Img)`
  max-height: 100%;
  height: 100%;
`

const BackgroundWrapper = styled.div`
  ${cover('absolute')};

  overflow: hidden;
`

const Hero = ({ cmsData, fluid }) => {
  const intl = useIntl()

  return (
    <Container>
      <Nav />
      <Inner>
        <Wrapper size='narrow'>
          <Content>
            <Title size={1}>{widowFix(cmsData.heading[0].text)}</Title>
            <Subtitle>{cmsData.description[0].text}</Subtitle>
            <ButtonGroup>
              <BookButton />
              <Button to='/neighbourhood' theme='tertiary'>
                {intl.formatMessage({ id: 'learnMore' })}
              </Button>
            </ButtonGroup>
          </Content>
        </Wrapper>
      </Inner>

      <BackgroundWrapper>
        <Background
          fluid={fluid}
          role='presentation'
          objectFit='cover'
          objectPosition='center'
          loading='eager'
          fadeIn={false}
        />
      </BackgroundWrapper>
    </Container>
  )
}

export default Hero
